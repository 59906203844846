import { Modal } from "react-bootstrap";

export default function TrackingModal({ coupon, trackingModal, setTrackingModal }) {
    const { address, redeem_date, activationUrl, order_number, updated_at, order_id } = coupon;
    return <>
        <Modal

            show={trackingModal}
            onHide={() => setTrackingModal(false)}
            onBackdropClick={() => setTrackingModal(false)}
            onClose={() => setTrackingModal(false)}
            centered
        >

            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>

                <div className="mc-tracking-modal-content">

                    <ul>
                        <li className="mc-tracking-info">
                            {order_id && <p className="w-100"><span className="key"><bold>Order Id:</bold></span><span className="value">{order_id}</span></p>}
                            {order_number && <p className="w-100"><span className="key"><bold>Tracking Id:</bold></span><span className="value">{order_number}</span></p>}
                            <p className="w-100"><span className="key"><bold>Status:</bold></span><span className="value">Order Placed</span></p>
                            {redeem_date && <p className="w-100"><span className="key"><bold>Order Date:</bold></span><span className="value">{`${new Date(redeem_date).toDateString()}`}</span></p>}
                            {activationUrl && <p className="w-100"><span className="key"><bold>Tracking URL:</bold></span><span className="value">{activationUrl || ' N/A '}</span></p>}
                            {address && <p className="w-100"><span className="key"><bold>Address:</bold></span><span className="value">{`${address?.street}, ${address?.city}, ${address?.state}, ${address?.zipCode}`}</span></p>}
                            {updated_at && <p className="w-100"><span className="key"><bold>Last Updated at:</bold></span><span className="value">{`${new Date(updated_at).toLocaleDateString()}, ${new Date(updated_at).toLocaleTimeString()}`}</span></p>}
                            {/* <span>
                                Tracking Details will be shared once order will be dispatched
                            </span> */}
                        </li>
                    </ul>

                    {false && <ul>
                        <li><span>Order Number</span><span></span></li>
                        <li><span>Delivery Address</span><span> Agra, UP - 282001</span></li>
                        <li><span>Status</span><span>In Transit</span></li>
                        <li><span>Tracking Link</span><span></span></li>
                        <li><span>Last Updated at</span><span>{new Date().toISOString()}</span></li>
                    </ul>}
                </div>

            </Modal.Body>

        </Modal>
    </>
}