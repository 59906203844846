import axios from "axios";
export const loggedUser = async (currentPage) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/user/loggedUser/detail?page=${currentPage}&size=${10}`, {
            headers: {
                "x-access-token": localStorage.getItem("token")
            }
        });
        return response;
    }catch(err){
        return err.message
    }
}