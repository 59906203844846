import axios from "axios";
export const getStatus = async (id, coupon_code) => {
  try {
    // console.log(id,coupon_code);
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}/api/user/almond/rewardstatus`,
      { id, coupon_code },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.message;
  }
};

// import axios from "axios";
// export const getStatus = async (id, coupon_code) => {
//     try {
//         console.log(id,coupon_code);
//         const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/user/orderStatus`, { id, coupon_code },{
//             headers: {
//                 "x-access-token": localStorage.getItem("token")
//             }
//         });
//         return response;
//     } catch (err) {
//         return err.message;
//     }
// }
