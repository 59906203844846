import axios from "axios";

export const loginWithMobile = async (mobile, coupon_code, almond_pin) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}/api/user/login/mobile/coupon/check`,
      {
        mobile,
        project_name: "Almonds Vouch",
        coupon_code,
        coupon_pin: almond_pin,
      },
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return response;
  } catch (err) {
    return err.message;
  }
};
