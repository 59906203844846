import { useEffect, useState, useContext } from "react";
import { Alert } from "react-bootstrap";
import { loginWithOtp } from "../service/login";
import { useNavigate } from "react-router-dom";
import { loginWithMobile } from "../service/loginWithMobile";
import { LoaderContext } from "../context/LoaderContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footer from "../layout/Footer";
import { loginWithCoupon } from "../service/loginWithCoupon";
import { ThemeContext } from "../context/ThemeProvider";
import { toast } from "react-toastify";
import { duration } from "moment";
import { useLocation } from "react-router-dom";

export default function Login() {
  const location = useLocation();
  // Use URLSearchParams to extract the query parameters
  const queryParams = new URLSearchParams(location.search);
  const _query_code = queryParams.get("c"); // replace 'param1' with your actual query param key
  const _query_pin = queryParams.get("p");
  const [otp, setOtp] = useState();
  const [currentDomain, setCurrentDomain] = useState("");
  const [submitBtn, setSubmitBtn] = useState(true);
  const { theme, setTheme } = useContext(ThemeContext);
  const [alert, setAlert] = useState({ state: false, variant: "", text: "" });
  const [isOtpSent, SetIsOtpSent] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [mobileAlert, setMobileAlert] = useState({ state: false });
  const [errorModal, setErrorModal] = useState(false);
  const [couponUser, setCouponUser] = useState(null);
  const [isWillingWithCoupon, setIsWillingWithCoupon] = useState(true);
  const [couponBtnState, setCouponBtnState] = useState(false);
  const [couponMobile, setCouponMobile] = useState(false);
  const [couponOtp, setCouponOtp] = useState(false);
  const [verifyOtpBtn, setVerifyOptBtn] = useState(false);
  const [sendOtpBtn, setSendOtpBtn] = useState(true);
  const [nameRequired, setNameRequired] = useState(false);
  const [otpTimeout, setOtpTimeout] = useState(60);

  useEffect(() => {
    if (_query_code && _query_pin) {
      setCouponUser({ coupon_code: _query_code, almond_pin: _query_pin });
    } else if (_query_code) {
      setCouponUser({ coupon_code: _query_code });
    } else if (_query_pin) {
      setCouponUser({ almond_pin: _query_pin });
    }
  }, []);

  useEffect(() => {
    if (otpTimeout > -1) {
      const intervalId = setInterval(() => {
        setOtpTimeout((otpTimeout) => otpTimeout - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [couponOtp]);

  const navigate = useNavigate();

  const { setLoading } = useContext(LoaderContext);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(window.location.hostname);
  }, []);

  useEffect(() => {
    console.log({ couponUser });
    if (couponUser) {
      const objKeys = Object.keys(couponUser);
      if (objKeys.includes("coupon_code") && objKeys.includes("almond_pin")) {
        setCouponMobile(true);
      } else {
        setCouponMobile(false);
      }
    }
  }, [couponUser]);

  const handleChange = async (e) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(otp);
  }, [otp]);

  const handleMobileInput = async (e) => {
    if (validateMobile(e.target.value)) {
      setMobile(e.target.value);
      setMobileAlert({ state: false, text: "" });
    } else {
      const input = e.target;
      input.value = input.value.replace(/[^0-9]/g, "");
    }
  };

  const validateMobile = (value) => {
    value.replace(/\D/g, "");
    if (value[0] < 5) {
      setMobileAlert({
        state: true,
        text: "Mobile number must not start with 0,1,2,3,4 and 5.",
      });
      return false;
    } else if (/^\d{10}$/.test(value)) {
      return true;
    } else {
      setMobileAlert({
        state: true,
        text: "Mobile number must contain 10 digits !",
      });
      return false;
    }
  };

  const validateCoupon = () => {
    if (!couponUser.coupon_code) {
      setAlert({
        state: true,
        variant: "danger",
        text: "Coupon code is required!",
      });
      return false;
    }

    if (!couponUser.almond_pin) {
      setAlert({
        state: true,
        variant: "danger",
        text: "Coupon pin is required!",
      });
      return false;
    }

    return true;
  };
  const handleSubmit = async () => {
    if (submitBtn) {
      setLoading(true);
      const { success, accessToken } = await loginWithOtp(
        parseInt(`${otp?.otp}`),
        mobile
      );
      if (success) {
        setLoading(false);
        setAlert({
          state: true,
          variant: "success",
          text: "OTP Submitted Successfully",
        });
        navigate("/");
        localStorage.setItem("token", accessToken);
      } else {
        setLoading(false);
        setAlert({ state: true, variant: "danger", text: "OTP did not match" });
      }
    }
  };

  const handleOtpSent = async () => {
    if (!mobileAlert.state) {
      try {
        if (mobile.length !== 10) {
          setAlert({
            state: true,
            variant: "danger",
            text: "Mobile number must be 10 digits long.",
          });
          return;
        }

        setLoading(true);
        const response = await loginWithMobile(mobile);
        // console.log(response);
        setLoading(false);
        if (response.data.success === true) {
          SetIsOtpSent(true);

          if (response.data.isUser) {
            setNameRequired(true);
          }
        } else {
          toast.error("Invalid mobile number");
        }
      } catch (err) {
        toast.error("Please provide mobile number");
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAlert({ state: false, variant: "", text: "" });
    }, 5000);
  }, [alert]);

  function clickEvent(first, last) {
    if (first.value.length) {
      document.getElementById(last).focus();
    }
  }
  const handleInputChange = async (e) => {
    setCouponUser({ ...couponUser, [e.target.name]: e.target.value });
    console.log(e.target.name, e.target.value);
  };

  const handleLoginWithCoupon = async (e) => {
    if (nameRequired) {
      if (!couponUser.fullName) {
        toast.error("Please enter your full name");
        return;
      }

      if (couponUser?.fullName?.split(" ").length < 2) {
        toast.error("Please enter your full name");
        return;
      }
    }

    if (!validateCoupon()) {
      return; // Stop further execution if validation fails
    }

    if (
      couponUser &&
      Object.values(couponUser).length > 1 &&
      isWillingWithCoupon
    ) {
      setLoading(true);

      // Send coupon data to the backend
      const response = await loginWithCoupon({
        ...couponUser,
        ...(currentDomain === "mastercardjnk.vouch.club" ||
        currentDomain === "13.200.128.220"
          ? { type: "mastercard" }
          : {}),
      });

      setLoading(false);
      console.log(response);

      if (response?.data?.success) {
        navigate("/");
        localStorage.setItem("token", response?.data?.accessToken);
      } else {
        setAlert({
          state: true,
          variant: "danger",
          text: "Invalid coupon code or pin!",
        });
      }
    } else {
      if (isWillingWithCoupon) toast.error("Please fill all the fields");
    }
  };

  useEffect(() => {
    console.log(couponUser);
  }, [couponUser]);

  useEffect(() => {
    setCurrentDomain(window.location.hostname);
  }, []);

  const [isPending, setIsPending] = useState(false);

  const handleCouponUserOtp = async () => {
    try {
      if (couponUser?.mobile?.length !== 10) {
        setAlert({
          state: true,
          variant: "danger",
          text: "Mobile number should be 10 digits long.",
        });
        return false;
      }
      if (!isPending) {
        setIsPending(true);
        const response = await loginWithMobile(
          couponUser.mobile,
          couponUser.coupon_code,
          couponUser.almond_pin
        );
        if (response.status === 200) {
          if (response.data.isUser) {
            setNameRequired(true);
          }
          if (!response.data.isValidCoupon) {
            toast.error("Invalid coupon code or pin!");
          } else {
            toast.success("Otp sent successfully");
            setCouponUser({ ...couponUser });
            setCouponOtp(true);
            setSendOtpBtn(false);
            setVerifyOptBtn(true);
            setOtpTimeout(60);
          }
        } else {
          toast.info(response.data.message);
        }
        setIsPending(false);
      }
    } catch (err) {
      console.log(err);
      setIsPending(false);
      return err.message;
    }
  };

  const handleCouponVerifyOtp = async () => {
    try {
      if (!couponUser.otp) {
        toast.error(`Please enter the otp.`, { duration: 3000 });
        return;
      }

      if (nameRequired) {
        if (!couponUser.fullName) {
          toast.error(`Please enter your full name.`, { duration: 3000 });
          return;
        }
      }

      const { success, accessToken } = await loginWithOtp(
        parseInt(`${couponUser?.otp}`),
        couponUser.mobile
      );
      if (success) {
        setLoading(false);
        setAlert({
          state: true,
          variant: "success",
          text: "OTP Submitted Successfully",
        });
        // navigate("/");
        setCouponBtnState(true);
        setVerifyOptBtn(false);
        handleLoginWithCoupon();
        localStorage.setItem("token", accessToken);
      } else {
        setLoading(false);
        setAlert({ state: true, variant: "danger", text: "OTP did not match" });
      }
    } catch (err) {
      console.log(err);
      return err.message;
    }
  };

  return (
    <>
      <div className="container">
        {alert.state && (
          <Alert variant={alert.variant || ""} dismissible>
            {alert.text || ""}
          </Alert>
        )}
        {currentDomain === "mastercardjnk.vouch.club" ||
        currentDomain === "13.200.128.220" ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Left image */}
            <img
              style={{ margin: "15px 0 40px" }}
              className="JK-login-logo"
              src={theme ? "/images/mcardDark.png" : "/images/mcard.png"}
              alt=""
            />

            {/* Right image */}
            <img
              style={{ margin: "0 0 40px" }}
              className="mcard-login-logo"
              src={theme ? "/images/j&kDark.png" : "/images/j&k.png"}
              alt=""
            />
          </div>
        ) : currentDomain === "hccb.vouch.club" ? (
          // Show a single logo centered for other domains
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Left image */}
            <img
              style={{ margin: "15px 0 40px" }}
              className="JK-login-logo almond_logo_with_hccb"
              src={
                theme
                  ? "/images/almond-logo-dark.png"
                  : "/images/almond-logo.png"
              }
              alt=""
            />

            {/* Right image */}
            <img
              style={{ margin: "0 0 40px" }}
              className="mcard-login-logo"
              src={theme ? "/images/hccb_logo.png" : "/images/hccb_logo.png"}
              alt=""
            />
          </div>
        ) : currentDomain === "actiontesa.almonds.ai" ? (
          // Show a single logo centered for other domains
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Left image */}
            <img
              style={{ margin: "15px 0 40px" }}
              className="JK-login-logo almond_logo_with_hccb"
              src={
                theme
                  ? "/images/almond-logo-dark.png"
                  : "/images/almond-logo.png"
              }
              alt=""
            />

            {/* Right image */}
            <img
              style={{ margin: "15px 0 40px" }}
              className="mcard-login-logo"
              src={
                theme ? "/images/action_tesa.jpg" : "/images/action_tesa.jpg"
              }
              alt=""
            />
          </div>
        ) : (
          <img
            style={{ margin: "0 auto 40px", display: "block" }}
            className="login-logo"
            src={
              theme ? "/images/almond-logo-dark.png" : "/images/almond-logo.png"
            }
            alt=""
          />
        )}
        {/* <img style={{ margin: "0 auto 40px" }} className="login-logo" src="/images/almond-logo.png" alt="" /> */}
        {!isWillingWithCoupon && (
          <>
            {!isOtpSent ? (
              <div className="mobile-container">
                {/* <h2>Please Enter your mobile number</h2> */}
                <div className="userInput">
                  <input
                    className="phone-input"
                    type="tel"
                    name="mobile"
                    disabled
                    maxLength={10}
                    onChange={handleMobileInput}
                    placeholder="Enter your mobile"
                  />
                  {mobileAlert.state && (
                    <div className="mobile-alert-label primary">
                      {" "}
                      {mobileAlert.text}
                    </div>
                  )}
                </div>
                <button
                  className={
                    !mobileAlert.state ? "btn-submit" : "faded btn-submit"
                  }
                  onClick={handleOtpSent}
                >
                  Send OTP
                </button>
              </div>
            ) : (
              <div className="otp-container">
                <h1>Verify OTP</h1>
                <h3>
                  We have sent you an One Time Password to your mobile or email
                </h3>
                <input
                  className="phone-input"
                  type="text"
                  maxLength={4}
                  onChange={handleChange}
                  name="otp"
                  style={{ margin: "30px auto" }}
                  placeholder="Enter your code"
                />
                <button
                  className={submitBtn ? "btn-submit" : "faded btn-submit"}
                  onClick={handleSubmit}
                >
                  Confirm
                </button>
              </div>
            )}
          </>
        )}
        {/* {Login with Coupon Form} */}
        {
          <div className="mc-login-with-coupon-container">
            {!isWillingWithCoupon && (
              <div className="mc-or-container">
                <div className="mc-underline"></div>
                <div className="mc-or">OR</div>
                <div className="mc-underline"></div>
              </div>
            )}
            {isWillingWithCoupon && (
              <>
                <input
                  className="phone-input mc-input-field"
                  type="text"
                  name="coupon_code"
                  disabled={queryParams.get("c") || couponOtp}
                  autoComplete="on"
                  maxLength={16}
                  onChange={handleInputChange}
                  placeholder="Enter your coupon code"
                  defaultValue={queryParams.get("c") || ""}
                />

                <input
                  className="phone-input mc-input-field"
                  type="text"
                  name="almond_pin"
                  disabled={queryParams.get("c") || couponOtp}
                  autoComplete="on"
                  maxLength={4}
                  onChange={handleInputChange}
                  placeholder="Enter your coupon pin"
                  defaultValue={queryParams.get("p") || ""}
                />

                {couponMobile && (
                  <>
                    <input
                      className="phone-input mc-input-field"
                      id="mc-coupon-user-mobile"
                      type="mobile"
                      name="mobile"
                      disabled={couponOtp}
                      autoComplete="on"
                      maxLength={10}
                      onChange={(e) => {
                        document.querySelector("#mc-coupon-user-mobile").value =
                          e.target.value.replace(/\D/g, "");
                        setCouponUser({
                          ...couponUser,
                          [e.target.name]: e.target.value.replace(/\D/g, ""),
                        });
                      }}
                      placeholder="Enter your Mobile Number"
                    />

                    {couponUser && couponOtp && (
                      <>
                        <input
                          className="phone-input mc-input-field"
                          id="mc-coupon-user-otp"
                          type="mobile"
                          name="otp"
                          maxLength={4}
                          onChange={(e) => {
                            document.querySelector(
                              "#mc-coupon-user-otp"
                            ).value = e.target.value.replace(/\D/g, "");
                            setCouponUser({
                              ...couponUser,
                              [e.target.name]: e.target.value.replace(
                                /\D/g,
                                ""
                              ),
                            });
                          }}
                          placeholder="Enter the OTP"
                        />

                        {nameRequired && (
                          <input
                            className="phone-input mc-input-field"
                            id="mc-coupon-user-name"
                            type="fullName"
                            name="fullName"
                            required={true}
                            // disabled={couponOtp}
                            autoComplete="on"
                            onChange={(e) => {
                              document.querySelector(
                                "#mc-coupon-user-name"
                              ).value = e.target.value.replace(
                                /[^a-zA-Z\s]/g,
                                ""
                              );
                              setCouponUser({
                                ...couponUser,
                                [e.target.name]: e.target.value.replace(
                                  /[^a-zA-Z\s]/g,
                                  ""
                                ),
                              });
                            }}
                            placeholder="Enter your Full Name"
                          />
                        )}

                        <div className="mc-resend-otp">
                          <p
                            className={
                              otpTimeout < 1
                                ? "mc-enable-otp"
                                : "mc-disable-otp"
                            }
                            onClick={() => {
                              if (otpTimeout < 1) {
                                handleCouponUserOtp();
                              }
                            }}
                          >
                            {otpTimeout < 0
                              ? `Resend OTP`
                              : `Resend OTP in ${otpTimeout} seconds`}
                          </p>
                        </div>
                        {verifyOtpBtn && (
                          <button
                            className="mc-coupon-send-otp"
                            onClick={handleCouponVerifyOtp}
                          >
                            Verify OTP
                          </button>
                        )}
                      </>
                    )}

                    {couponMobile && sendOtpBtn && (
                      <>
                        <button
                          className={`mc-coupon-send-otp ${
                            isPending ? "faded" : ""
                          }`}
                          onClick={handleCouponUserOtp}
                          onTouch={handleCouponUserOtp}
                          onTouchStart={handleCouponUserOtp}
                        >
                          Send OTP
                        </button>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {!verifyOtpBtn && couponUser && couponOtp && (
              <button
                className={couponBtnState ? "btn-submit" : "faded btn-submit"}
                onClick={() => {
                  setIsWillingWithCoupon(true);
                  handleLoginWithCoupon();
                }}
              >
                Login
              </button>
            )}
          </div>
        }

        {
          <Modal show={errorModal} centered>
            <img className="mc-check-gif" src="images/cross.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">Invalid Credentials !</h3>
              {/* <p>Invalid Credentials !</p> */}
              <div className="mc-product-desc-container description"></div>
            </div>

            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button"
                variant="danger"
                onClick={() => {
                  setErrorModal(false);
                }}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
    </>
  );
}
