import axios from "axios";

export const loginWithOtp = async (otp, mobile) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}/api/user/otpverify`,
      { mobile, otp }
    );
    return response.data;
  } catch (err) {
    return err.message;
  }
};
